import React, { useState, useMemo } from 'react'
import { observer, inject } from 'mobx-react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { serviceMembersContainerStyles } from './styles.module.scss'
import { PlaneLoader, Modal } from 'components'
import MembersTable from './MembersTable'
import MembersFilter from './MembersFilter'

const ACTIONS = {
  ASSIGN: 'assign',
  REMOVE: 'remove'
}

const ServiceMembersContainer = ({ userDataStore, serviceMembersStore }) => {
  const [searchValue, setSearchValue] = useState('')
  const [modalAction, setModalAction] = useState(null)
  const [selectedMember, setSelectedMember] = useState(null)
  const [viewAssignedOnly, setViewAssignedOnly] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const filteredMembers = useMemo(() => {
    const members = viewAssignedOnly
      ? serviceMembersStore.members.filter(member => member.counselorId === userDataStore.user.id)
      : serviceMembersStore.members

    const searchLower = searchValue.toLowerCase()
    return members.filter(member =>
      ['firstName', 'lastName', 'email', 'phoneNumber'].some(field =>
        member[field]?.toString().toLowerCase().includes(searchLower)
      )
    )
  }, [searchValue, viewAssignedOnly, serviceMembersStore.areMembersLoading, serviceMembersStore.members])

  const handleAssignment = (member, action) => {
    setSelectedMember(member)
    setModalAction(action)
    setModalVisible(true)
  }

  const handleModalConfirm = () => {
    const actions = {
      [ACTIONS.ASSIGN]: () => serviceMembersStore.assignMemberToCounselor(selectedMember.id),
      [ACTIONS.REMOVE]: () => serviceMembersStore.removeMemberAssignment(selectedMember.id),
    }

    if (selectedMember && actions[modalAction]) {
      actions[modalAction]()
    }

    setModalVisible(false)
  }

  return (
    <div className={serviceMembersContainerStyles}>
      <PlaneLoader loading={serviceMembersStore.areMembersLoading}>
        <>
          <MembersFilter
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            viewAssignedOnly={viewAssignedOnly}
            onToggleChange={() => setViewAssignedOnly(prev => !prev)}
          />
          <MembersTable
            members={filteredMembers}
            onAssignMember={(member) => handleAssignment(member, ACTIONS.ASSIGN)}
            onRemoveAssignment={(member) => handleAssignment(member, ACTIONS.REMOVE)}
          />
        </>
      </PlaneLoader>
      <Modal
        visible={modalVisible || serviceMembersStore.isActionInProgress}
        confirmLoading={serviceMembersStore.isActionInProgress}
        onOk={handleModalConfirm}
        onCancel={() => setModalVisible(false)}
        title={
          <>
            {modalAction === ACTIONS.REMOVE && (
              <ExclamationCircleOutlined style={{color: '#ff4d4f', marginRight: 8}}/>
            )}
            {modalAction === ACTIONS.ASSIGN ? "Assign Service Member" : "Remove Service Member"}
          </>
        }
      >
      {modalAction === ACTIONS.ASSIGN
        ? (
          <>
            Are you sure you want to <b>assign {selectedMember?.firstName} {selectedMember?.lastName}</b> to yourself?
          </>
        ) : (
          <>
            <b>Warning:</b> You are about to <b>remove</b> the assignment
            for <b>{selectedMember?.firstName} {selectedMember?.lastName}</b>.
            This action cannot be undone.
          </>
      )}
      </Modal>
    </div>
  )
}

export default inject('userDataStore')(inject('serviceMembersStore')(observer(ServiceMembersContainer)))