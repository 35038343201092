import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { UserAddOutlined, CloseOutlined } from '@ant-design/icons'
import { Table, Tooltip, Avatar, Button } from 'components'
import * as paths from 'utils/path-helpers/app'

const formatPhoneNumber = (number) => {
  if (!number || typeof number !== 'string') {
    return number
  }

  const match = number.match(/^\+1?(\d{3})(\d{3})(\d{4})$/)
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : number
}

const stringComparator = (a, b) => (a.toLowerCase() > b.toLowerCase() ? -1 : 1)

const MemberAvatar = ({ avatar, firstName, lastName, userId }) => (
  <Tooltip title={`${firstName} ${lastName}`}>
    <Link to={paths.userProfileUserIdPath(userId)}>
      <Avatar src={avatar} alt={`${firstName} ${lastName}`} size="large">
        {firstName[0]}{lastName[0]}
      </Avatar>
    </Link>
  </Tooltip>
)

const AssignedTo = ({ counselorId, record, onAssignMember, onRemoveAssignment }) => (
  counselorId ? (
    <div className="assigned-to-column">
      <Avatar
        src={record.counselor?.avatar}
        alt={`${record.counselor?.firstName} ${record.counselor?.lastName}`}
        size="small"
      >
        {record.counselor?.firstName[0]}{record.counselor?.lastName[0]}
      </Avatar>
      {record.counselor?.firstName} {record.counselor?.lastName}
      <Button
        type="link"
        icon={<CloseOutlined />}
        onClick={() => onRemoveAssignment(record)}
        size="small"
      />
    </div>
  ) : (
    <Button
      icon={<UserAddOutlined />}
      onClick={() => onAssignMember(record)}
      size="small"
    >
      Claim
    </Button>
  )
)

const MembersTable = ({ members, onAssignMember, onRemoveAssignment }) => {
  const columns = useMemo(() => [
    {
      title: '',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (avatar, record) => (
        <MemberAvatar
          avatar={avatar}
          firstName={record.firstName}
          lastName={record.lastName}
          userId={record.id}
        />
      ),
      align: 'right',
      width: '10%',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: (a, b) => stringComparator(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`),
      render: (fullName, record) => (
        <Link to={paths.userProfileUserIdPath(record.id)}>
          {record.firstName} {record.lastName}
        </Link>
      ),
      align: 'left',
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => stringComparator(a.email, b.email),
      align: 'center',
      width: '20%',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: formatPhoneNumber,
      align: 'center',
      width: '160px',
    },
    {
      title: 'Assigned To',
      dataIndex: 'counselorId',
      key: 'counselorId',
      render: (counselorId, record) => (
        <AssignedTo
          counselorId={counselorId}
          record={record}
          onAssignMember={onAssignMember}
          onRemoveAssignment={onRemoveAssignment}
        />
      ),
      align: 'center',
      width: '30%',
    },
  ], [onAssignMember, onRemoveAssignment])

  return (
    <Table
      className="service-members-table"
      dataSource={members}
      columns={columns}
      rowKey="id"
      bordered
      pagination={{ pageSize: 10 }}
    />
  )
}

export default MembersTable