import React, { useState } from 'react'
import { moduleStepStyles, moduleStepModalTitleStyles, moduleStepTceModalStyles } from './styles.module.scss'
import { Button, Dropdown, Menu, Modal, DatePicker, Form, Popover, Badge } from 'components'
import { observer, inject } from 'mobx-react'
import { PROGRESS_STATUSES, LOADING_ICON, SELECT_STATUSES } from 'constants/modules'
import ReactPlayer from 'react-player'
import {
  VideoCameraOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
  DollarCircleOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  AreaChartOutlined,
  WalletOutlined,
} from '@ant-design/icons'

import { Link } from 'react-router-dom'

const IconPack = {
  VideoCameraOutlined: <VideoCameraOutlined />,
  CalendarOutlined: <CalendarOutlined />,
  InfoCircleOutlined: <InfoCircleOutlined />,
}

const dueDateRules = [
  {
    required: true,
    message: 'Please provide due date',
  },
]

const ModuleStep = props => {
  const [ activeModalAction, setActiveModalAction ] = useState({})
  const [ isModalVisible, setIsModalVisible ] = useState(false)

  const renderContentAction = (showButton, id, buttonName) => {
    if (!showButton) return null

    return <Button className="content-action" onClick={() => onContentActionClick(id)}>{buttonName}</Button>
  }

  const renderActionContent = ({ id, type, content, eventTemplateId, subButtonName, trackableForRequest }) => {
    if (type === 'text') {
      return (
        <div>
          {content}
        </div>
      )
    }

    if (type === 'html') {
      return (
        <>
          <div className="content-type-html-content" dangerouslySetInnerHTML={{__html: content}}/>
          {renderContentAction(trackableForRequest, id, subButtonName)}
        </>
      )
    }

    if (type === 'image') {
      return (
        <>
          <img alt="" style={{ width: '100%' }} src={content}/>
          {renderContentAction(trackableForRequest, id, subButtonName)}
        </>
      )
    }

    if (type === 'video') {
      return (
        <>
          <ReactPlayer
            width="100%"
            url={content}
          />
          {renderContentAction(trackableForRequest, id, subButtonName)}
        </>
      )
    }

    if (type === 'event') {
      const onFormSubmit = values => {
        const payload = {
          eventTemplateId,
          date: (new Date()).toISOString(),
        }

        props.eventStore.createEvent(payload)
      }

      return (
        <div>
          <Form id="event-form" onFinish={onFormSubmit}>
            <Form.Item label="Due date" name="date" rules={dueDateRules}>
              <DatePicker />
            </Form.Item>
            <Button htmlType="submit">
              Submit
            </Button>
          </Form>
          {renderContentAction(trackableForRequest, id, subButtonName)}
        </div>
      )
    }
  }

  const renderActionModalTitle = () => (
    <div className={moduleStepModalTitleStyles}>
      {`${props.index}. ${props.title}`}
      {props.careerStore.isActivePathDataReloading ? (
          <div
            style={{ color: LOADING_ICON.color }}
            className="module-step-status module-step-circle"
            title={LOADING_ICON.label}
          >
            {LOADING_ICON.icon}
          </div>
        ) : (
          returnStepStatus()
        )
      }
    </div>
  )

  const returnDrillInButton = props => {
    if (!props.steps.length) return null

    return (
      <Button type="primary">
        <Link to={`${props.match.url}/${props.id}`}>
          {props.drillInButtonName || 'Default'}
        </Link>
      </Button>
    )
  }

  const returnMenu = (props) => (
    Object.values(PROGRESS_STATUSES).map(statusObject => (
      <Menu.Item
        disabled={props.userDataStore.selectedMember}
        onClick={() => props.careerStore.postStepProgress({
          moduleId: props.activeModuleId,
          stepId: props.id,
          status: statusObject.action,
          pathId: props.careerStore.activePath.id,
        })}
        key={statusObject.actionLabel}
      >
        <span>
          {statusObject.icon}
        </span>
        {statusObject.actionLabel}
      </Menu.Item>
    ))
  )

  const renderStepTCEContent = stepReference => (
    <div className={moduleStepTceModalStyles}>
      <div className="module-step-tce-modal-item">
        <div className="module-step-tce-modal-item-field">
          <div className="module-step-tce-modal-item-field-name">
            <ClockCircleOutlined />
          </div>
          Time
        </div>
        <div>
          <div className="module-step-tce-modal-item-value-label">
            Estimated
          </div>
          <div className="module-step-tce-modal-item-value">
            {stepReference.readableRangeTime}
          </div>
        </div>
        <div>
          <div className="module-step-tce-modal-item-value-label">
            Average
          </div>
          <div className="module-step-tce-modal-item-value">
            {stepReference.readableAverageTime}
          </div>
        </div>
      </div>

      <div className="module-step-tce-modal-item">
        <div className="module-step-tce-modal-item-field">
          <div className="module-step-tce-modal-item-field-name">
            <DollarOutlined />
          </div>
          Cost
        </div>
        <div>
          <div className="module-step-tce-modal-item-value-label">
            Estimated
          </div>
          <div className="module-step-tce-modal-item-value">
            {stepReference.readableRangeCost}
          </div>
        </div>
        <div>
          <div className="module-step-tce-modal-item-value-label">
            Average
          </div>
          <div className="module-step-tce-modal-item-value">
            {stepReference.readableAverageCost}
          </div>
        </div>
      </div>

      <div className="module-step-tce-modal-item">
        <div className="module-step-tce-modal-item-field">
          <div className="module-step-tce-modal-item-field-name">
            <AreaChartOutlined />
          </div>
          Earnings
        </div>
        <div>
          <div className="module-step-tce-modal-item-value-label">
            Predicted:
          </div>
          <div className="module-step-tce-modal-item-value">
            {stepReference.readablePredictedEarnings}
          </div>
        </div>
      </div>

      <div className="module-step-tce-modal-item">
        <div className="module-step-tce-modal-item-field">
          <div className="module-step-tce-modal-item-field-name">
            <WalletOutlined />
          </div>
          Salary
        </div>
        <div>
          <div className="module-step-tce-modal-item-value-label">
            Predicted:
          </div>
          <div className="module-step-tce-modal-item-value">
            {stepReference.readableAverageSalary}
          </div>
        </div>
      </div>
    </div>
  )

  const returnInfoButton = props => {
    if (!props.stepReference.isTCEApplicable) return null

    return (
      <div
        className="module-step-info module-step-circle"
        onClick={() => Modal.info({
          content: renderStepTCEContent(props.stepReference),
          width: '800px',
          icon: null,
          maskClosable: true,
          closable: true,
          okButtonProps: {
            shape: 'round'
          },
        })}
      >
        <DollarCircleOutlined />
      </div>
    )
  }

  const returnStepStatus = () => {
    if (props.viewModuleOrStep.type === 'select') {
      const currentSelection = SELECT_STATUSES[props.selected ? 'selected' : 'unselected']

      const payload = {
        value: props.id,
        moduleId: props.activeModuleId,
        stepId: props.match.params.viewDataId,
        pathId: props.careerStore.activePath.id,
      }

      return (
        <div
          onClick={() => props.careerStore.postCareerSelection(payload)}
          style={{ color: currentSelection.color }}
          className="module-step-status module-step-circle"
          title={currentSelection.label}
        >
          {currentSelection.icon}
        </div>
      )
    }

    const menu = (
      <Menu>
        {returnMenu(props)}
      </Menu>
    )

    const currentStatus = PROGRESS_STATUSES[props.status] || PROGRESS_STATUSES.onHold

    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        disabled={props.steps.length}
      >
        <div
          style={{ color: currentStatus.color }}
          className="module-step-status module-step-circle"
          title={currentStatus.label}
        >
          <Popover
            placement="topRight"
            content="Complete Child Steps to Update Status"
            trigger={['hover', 'click']}
            {...(props.steps.length ? {} : { visible: false })}
          >
            <Badge dot={!!props.steps.length}>
              {currentStatus.icon}
            </Badge>
          </Popover>
        </div>
      </Dropdown>
    )
  }

  const onActionButtonClick = action => {
    setIsModalVisible(true)
    setActiveModalAction(action)
  }

  const onContentActionClick = (id) => {
    props.careerStore.updateStepActionAccessDate(id)
  }

  return (
    <div className={moduleStepStyles}>
      <Modal
        title={renderActionModalTitle()}
        visible={isModalVisible}
        footer={[
          <Button
            type="primary"
            children="Back"
            onClick={() => setIsModalVisible(false)}
          />
        ]}
        closable={false}
        afterClose={() => setActiveModalAction({})}
        destroyOnClose
        onCancel={() => setIsModalVisible(false)}
      >
        {renderActionContent(activeModalAction)}
      </Modal>
      <div className="module-step-index module-step-circle">
        {props.index}
      </div>
      {returnInfoButton(props)}
      {returnStepStatus(props)}
      <div className="module-step-content">
        <div>
          <span className="module-step-title">
            {props.title}
          </span>
          <br/>
          <span className="module-step-description">
            {props.description}
          </span>
        </div>
      </div>
      <div className="module-step-actions-wrapper">
        {props.actions && props.actions.map(action => (
          <Button
            onClick={() => onActionButtonClick(action)}
            type={action.color}
            icon={action.icon ? IconPack[action.icon] : null}
            key={action.label}
          >
            {action.label}
          </Button>
        ))}
        {returnDrillInButton(props)}
      </div>
    </div>
  )
}

export default inject('eventStore')(inject('userDataStore')(inject('careerStore')(observer (ModuleStep))))


