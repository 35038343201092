import { types, flow } from 'mobx-state-tree'
import * as stores from 'stores'
import { navigateToPathways } from 'utils/navigation'
import { accessToken } from 'utils/auth'
import { USER_ROLES } from 'constants/roles'

const MasterStore = types
  .model({})
  .actions(self => ({
    onInit: flow(function*() {
      if (accessToken.get()) {
        yield stores.userDataStore.getCurrentUser()

        stores.eventStore.getEventTemplates()
        stores.resourcesStore.getResources()
        stores.gearSavingsStore.getGearSavings()

        yield stores.surveyStore.getSurveyUnit()

        if (stores.userDataStore.user.role === USER_ROLES.COUNSELOR) stores.serviceMembersStore.getServiceMembers()
        if (stores.surveyStore.unit.id) navigateToPathways()

        yield stores.careerStore.getDataInitially()

        // TODO: no need to optimize for prod purposes but only for dev...
        if (window.env !== 'prod') stores.careerStore.getMasterPaths()
      }
    }),
    onAfterRetake: flow(function*() {
      stores.careerStore.resetStoreData()
      stores.surveyStore.getSurveyUnit()

      navigateToPathways()
    }),
  }))

export const masterStore = MasterStore.create()
