import React, { useEffect, useState } from 'react'
import { timelineAssistantContainerStyles } from './styles.module.scss'
import {
  Collapse,
  List,
  Progress,
  Button,
  Modal,
  Menu,
  Dropdown,
  PlaneLoader
} from 'components'
import ReactPlayer from 'react-player'
import { observer, inject } from 'mobx-react'
import TimelineAssistantToolbox from './TimelineAssistantToolbox'
import { PROGRESS_STATUSES } from 'constants/modules'
import Shepherd from 'shepherd.js'

const introSelectors = [
  '#add-event-button',
  '#view-switch-wrapper',
  '.ant-collapse-header',
  '.ant-collapse-content-active',
  '.timeline-assistant-event-title-status',
]

const TimelineAssistantContainer = props => {
  const [ demoActivePanelId, setDemoActivePanelId ] = useState(undefined)

  useEffect(() => {
    switch (props.eventStore.displayType) {
      case 'event':
        props.eventStore.getUserEvents(props.userDataStore.selectedMember)
        break
      case 'task':
        props.eventStore.getUserTasks(props.userDataStore.selectedMember)
        break
    }
  }, [props.eventStore.displayType, props.userDataStore.selectedMember])

  const tour = new Shepherd.Tour({
    tourName: 'timeline-assistant-tour',
    defaultStepOptions: {
      canClickTarget: false,
      scrollTo: true,
      exitOnEsc: true,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 5,
      cancelIcon: {
        enabled: true,
      },
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 15],
            },
          },
        ],
      },
      buttons: [
        {
          classes: 'ant-btn custom-button ant-btn-secondary ant-btn-round override-shepherd-button',
          text: 'Back',
          action: function() {
            this.back()
          },
        },
        {
          classes: 'ant-btn custom-button ant-btn-primary ant-btn-round override-shepherd-button',
          text: 'Next',
          action: function() {
            this.next()
          },
        },
      ],
    },
    useModalOverlay: true,
    steps: [
      {
        attachTo: { element: introSelectors[0], on: 'auto' },
        text: 'Use this button to directly add events to your timeline assistant.',
        when: {
          show: function() {
            setDemoActivePanelId(null)
          },
        },
      },
      {
        attachTo: { element: introSelectors[1], on: 'auto' },
        text: 'We offer two convenient options for viewing your upcoming tasks.',
        when: {
          show: function() {
            setDemoActivePanelId(null)
          },
        },
      },
      {
        attachTo: { element: introSelectors[2], on: 'auto' },
        text: 'When in the event view, tasks are organized and consolidated by event. Click anywhere in the block to expand the tasks.',
        when: {
          show: function() {
            setDemoActivePanelId(props.eventStore.userEvents.length ? props.eventStore.userEvents[0].id : undefined)
          },
        },
      },
      {
        attachTo: { element: introSelectors[3], on: 'auto' },
        text: 'Associated tasks and due dates will be displayed under each event.'
      },
      {
        attachTo: { element: introSelectors[4], on: 'auto' },
        text: 'Tasks can be marked as pending, in progress, complete, or skipped. Click on the icon to change the status of a task.'
      },
      {
        text: 'Congratulations! You are now ready to take BreakTurn Academy out flying!',
        buttons: [
          {
            classes: 'ant-btn custom-button ant-btn-secondary ant-btn-round override-shepherd-button',
            text: 'Back',
            action: function() {
              this.back()
            },
          },
          {
            classes: 'ant-btn custom-button ant-btn-primary ant-btn-round override-shepherd-button',
            text: 'Finish',
            action: function() {
              this.next()
            },
          },
        ],
      },
    ]
  })

  useEffect(() => {
    const onInactive = inactiveTour => {
      if (tour.tourName === inactiveTour.tourName) {
        setDemoActivePanelId(undefined)
        props.userDataStore.setShowTimelineDemo(false)
        props.userDataStore.completeTimelineAssistantDemo()
      }
    }

    Shepherd.on('inactive', onInactive)

    return () => {
      if (tour.isActive()) tour.cancel()
      Shepherd.off('inactive', onInactive)
    }
  }, [])

  useEffect(() => {
    if (props.userDataStore.isUserDataLoading) return
    if (props.userDataStore.isSubscriptionFree) return

    if (!props.userDataStore.isShowTimelineDemo) {
      if (props.userDataStore.user.jsonStorage.isTimelineDemoSkipped) return
      if (props.userDataStore.user.jsonStorage.isTimelineDemoFinished) return
    }

    Modal.confirm({
      title: 'Let’s Preflight!',
      content: 'Take 60 Seconds to Preflight the Timeline Assistant. Feel free to use your arrow keys to navigate.',
      okText: 'Show demo',
      cancelText: 'Skip demo',
      onOk: () => {
        tour.start()
      },
      onCancel: () => {
        props.userDataStore.setSkipTimelineDemo(true)
        props.userDataStore.setShowTimelineDemo(false)
      },
      icon: null,
      maskClosable: true,
    })
  }, [
    props.userDataStore.isUserDataLoading,
    props.userDataStore.isSubscriptionFree,
    props.userDataStore.isShowTimelineDemo,
    props.userDataStore.user.jsonStorage.isTimelineDemoFinished,
  ])

  const returnTaskModalContent = ({ contentType, content }) => {
    if (contentType === 'text') {
      return (
        <div>
          {content}
        </div>
      )
    }

    if (contentType === 'html') {
      return (
        <div className="content-type-html-content" dangerouslySetInnerHTML={{__html: content}} />
      )
    }

    if (contentType === 'image') {
      return (
        <img alt="" style={{ width: '100%' }} src={content}/>
      )
    }

    if (contentType === 'video') {
      return (
        <ReactPlayer
          width="100%"
          url={content}
        />
      )
    }
  }

  const returnEventStat = (label, value) => (
    <div className="timeline-assistant-event-stat">
      <div className="timeline-assistant-event-stat-label">{label}</div>
      <div className="timeline-assistant-event-stat-value">{value}</div>
    </div>
  )

  const returnEventStatistics = event => (
    <>
      {returnEventStat('Time Remaining', event.humanizedTimeRemaining)}
      {returnEventStat('Tasks Completed', `${event.completedCount}/${event.tasksAmount}`)}
      {returnEventStat('Tracker', <Progress status={event.isOverdue ? "exception" : undefined} showInfo={false} percent={event.completedTasksPer} />)}
    </>
  )

  const returnEventTitle = event => (
    <div className="timeline-assistant-event-header">
      <div className="timeline-assistant-event-title-wrapper">
        <h2 className="timeline-assistant-event-title">
          {event.title}
        </h2>
      </div>
      <div className="timeline-assistant-stat-wrapper">
        {returnEventStatistics(event)}
      </div>
    </div>
  )

  const returnModuleDescription = module => (
    <div>
      <div className="timeline-assistant-module-description">
        {module.description}
      </div>
    </div>
  )

  const returnMenu = task => (
    Object.values(PROGRESS_STATUSES).map(statusObject => (
      <Menu.Item
        disabled={props.userDataStore.selectedMember}
        onClick={() => task.changeStatus(statusObject.action)}
        key={statusObject.actionLabel}
      >
        <span>
          {statusObject.icon}
        </span>
        {statusObject.actionLabel}
      </Menu.Item>
    ))
  )

  const returnTaskStatus = task => {
    const currentStatus = PROGRESS_STATUSES[task.status] || PROGRESS_STATUSES.onHold

    const menu = (
      <Menu>
        {returnMenu(task)}
      </Menu>
    )

    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
      >
        <div
          style={{ color: currentStatus.color }}
          className="timeline-assistant-event-title-status"
          title={currentStatus.label}
        >
          {currentStatus.icon}
        </div>
      </Dropdown>
    )
  }

  const taskItemRenderer = task => (
    <List.Item
      key={task.id}
    >
      <div className="timeline-assistant-task-item-wrapper">
        <div>
          <div className="timeline-assistant-task-title-wrapper">
            {returnTaskStatus(task)}
            <div className="timeline-assistant-task-title">
              {task.title}
            </div>
            {task.contentType ? (
              <Button
                size="small"
                type="primary"
                onClick={() => Modal.info({
                  content: returnTaskModalContent(task),
                  width: '800px',
                  icon: null,
                  maskClosable: true,
                  closable: true
                })}
              >
              View
              </Button>
            ) : (
              null
            )}
          </div>
          <div className="timeline-assistant-task-description">
            {task.description}
          </div>
        </div>
        <div className="timeline-assistant-due-date">
          <div>
            {task.humanizedDueDate}
          </div>
        </div>
      </div>
    </List.Item>
  )

  const returnEventDueTime = humanizedDueDate => (
    <div className="timeline-assistant-due-date">
      <h3>
        Due on:
      </h3>
      <div>
        {humanizedDueDate}
      </div>
    </div>
  )

  const returnTimelineEventPanel = event => (
    <Collapse.Panel
      header={returnEventTitle(event)}
      key={event.id}
      extra={returnEventDueTime(event.humanizedDueDate)}
      className="timeline-assistant-event-panel"
    >
      <List
        itemLayout="vertical"
      >
        {event.tasks.map(taskItemRenderer)}
      </List>
    </Collapse.Panel>
  )

  const returnTimelineContent = () => {
    const returnNoEventsPlaceholder = () => (
      <div className="timeline-assistant-no-data-placeholder">
        <h2>
          You don't have any upcoming events!
        </h2>
      </div>
    )

    switch (props.eventStore.displayType) {
      case 'event':
        if (!props.eventStore.userEvents.length) return returnNoEventsPlaceholder()

        return (
          <Collapse {...(demoActivePanelId !== undefined ? { activeKey: demoActivePanelId } : {})} >
            {props.eventStore.userEvents.map(returnTimelineEventPanel)}
          </Collapse>
        )

      case 'task':
        if (!props.eventStore.userTasks.length) return returnNoEventsPlaceholder()

        return (
          <List itemLayout="vertical">
            {props.eventStore.userTasks.map(taskItemRenderer)}
          </List>
        )
    }
  }

  const isLoaderVisible = () => {
    if (props.eventStore.displayType === 'event' && props.eventStore.areUserEventsLoading) return true
    if (props.eventStore.displayType === 'task' && props.eventStore.areUserTasksLoading) return true

    return false
  }

  return (
    <div className={timelineAssistantContainerStyles}>
      <PlaneLoader loading={isLoaderVisible()}>
        <TimelineAssistantToolbox />
        {returnTimelineContent()}
      </PlaneLoader>
    </div>
  )
}

export default inject('userDataStore')(inject('masterStore')(inject('eventStore')(observer (TimelineAssistantContainer))))
