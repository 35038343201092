import React, { useEffect, useState } from 'react'
import { Layout, Menu, Form, Input, Avatar, Collapse } from 'antd'
import {
  CalendarOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  ThunderboltOutlined
} from '@ant-design/icons'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { PlaneLoader } from 'components'
import { userProfileContainerStyles } from './styles.module.scss'

const { Sider, Content } = Layout
const { Panel } = Collapse

const UserProfileContainer = ({ match, userDataStore }) => {
  const [selectedMenu, setSelectedMenu] = useState('1')
  const [userData, setUserData] = useState({})
  const [collapsed, setCollapsed] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await userDataStore.getUserById(match.params.userId)
      setUserData(data)
    }

    fetchUserData()
  }, [match.params.userId, userDataStore])

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        ...userData,
        birthDate: moment(userData.birthDate).format("MM/DD/YYYY"),
      })
    }
  }, [userData, form])

  const onMenuClick = ({ key }) => {
    setSelectedMenu(key)
  }

  const renderSurveyQuestions = () => {
    if (!userData.surveyQuestions || userData.surveyQuestions.length === 0) {
      return <p>No survey questions available</p>
    }

    return (
      <Collapse defaultActiveKey={['1']} className="survey-collapse">
        {userData.surveyQuestions.map((unit, unitIndex) => (
          <Panel header={unit.unitTitle} key={unitIndex + 1}>
            {unit.questionsAndAnswers.map((qa, qaIndex) => (
              <div key={qaIndex} className="question-answer">
                <strong>{qa.question.replace(/\?$/, '')}:</strong> {qa.answer}
              </div>
            ))}
          </Panel>
        ))}
      </Collapse>
    )
  }

  return (
    <PlaneLoader loading={userDataStore.isUserProfileLoading}>
      <Layout className={userProfileContainerStyles}>
        <Sider
          className="user-profile-sider"
          theme="light"
          breakpoint="sm"
          collapsed={collapsed}
          collapsedWidth={81}
          onCollapse={(collapsedState) => setCollapsed(collapsedState)}
        >
          <Avatar size={collapsed ? 50 : 100} src={userData.avatar} className="user-avatar">
            {userData?.firstName?.[0]}
            {userData?.lastName?.[0]}
          </Avatar>

          <Menu
            mode="vertical"
            selectedKeys={[selectedMenu]}
            onClick={onMenuClick}
            className="user-profile-menu"
          >
            <Menu.Item key="1" title="About">
              {collapsed ? <InfoCircleOutlined /> : 'About'}
            </Menu.Item>
            <Menu.Item key="2" title="Activity">
              {collapsed ? <ThunderboltOutlined /> : 'Activity'}
            </Menu.Item>
            <Menu.Item key="3" title="Timeline (Coming soon)" disabled>
              {collapsed ? <CalendarOutlined /> : 'Timeline (Coming soon)'}
            </Menu.Item>
            <Menu.Item key="4" title="Messaging (Coming soon)" disabled>
              {collapsed ? <MessageOutlined /> : 'Messaging (Coming soon)'}
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout className="user-profile-content-layout">
          <Content className="user-profile-content">
            {selectedMenu === '1' && (
              <Form layout="vertical" form={form}>
                <Form.Item name="firstName" label="First Name">
                  <Input placeholder="First Name" readOnly/>
                </Form.Item>

                <Form.Item name="lastName" label="Last Name">
                  <Input placeholder="Last Name" readOnly/>
                </Form.Item>

                <Form.Item name="birthDate" label="Birthdate">
                  <Input placeholder="Birth Date" readOnly/>
                </Form.Item>

                {renderSurveyQuestions()}
              </Form>
            )}

            {selectedMenu === '2' && (
              <>
                <h3>Activity</h3>
                <p>
                  <strong>Registration Date:</strong> {moment(userData.registrationDate).format('MM/DD/YYYY')}
                </p>
                <p>
                  <strong>Last Login Date:</strong> {moment(userData.lastLoginDate).format('MM/DD/YYYY')}
                </p>
              </>
            )}
          </Content>
        </Layout>
      </Layout>
    </PlaneLoader>
  )
}

export default inject('userDataStore')(observer(UserProfileContainer))