import React, { useEffect } from 'react'
import { accountTileCenteredContainerStyles } from './styles.module.scss'
import { Button, Input, Form, DatePicker, Breadcrumb, InputNumber, PlaneLoader } from 'components'
import * as paths from 'utils/path-helpers/app'
import { observer, inject } from 'mobx-react'
import moment from 'moment'

const emailRules = [
  {
    required: true,
    message: 'Please provide your email',
  },
  {
    type: 'email',
    message: 'Please provide valid email',
  },
]

const firstNameRules = [
  {
    required: true,
    message: 'Please provide your first name',
  },
  {
    min: 2,
    message: 'Min first name length == 2',
  },
]

const lastNameRules = [
  {
    required: true,
    message: 'Please provide your last name',
  },
  {
    min: 2,
    message: 'Min first name length == 2',
  },
]

const birthDateRules = [
  {
    required: true,
    message: 'Please provide your birth date',
  },
]

const retirementAgeRules = [
  {
    required: true,
    message: 'Please provide your retirement age',
  },
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !getFieldValue('birthDate') || moment().diff(getFieldValue('birthDate'), 'years') <= value - 5) {
        return Promise.resolve()
      }
      return Promise.reject('Retirement age should be at least 5 years greater than your current age')
    },
  }),
]

const initialValues = {
  firstName: '',
  lastName: '',
  birthDate: '',
  retirementAge: 0,
}

const UserInfoContainer = props => {
  const [ form ] = Form.useForm()
  const { email, firstName, lastName, birthDate, retirementAge } = props.userDataStore.user

  useEffect(
    () => {
      form.setFieldsValue({
        email,
        firstName,
        lastName,
        retirementAge,
        birthDate: moment(birthDate, 'YYYY-MM-DD'),
      })
    }, [email, firstName, lastName, birthDate, retirementAge, form]
  )

  const onFormSubmit = ({ birthDate, ...values }) => {
    const userPayload = {
      birthDate: birthDate.format(),
      ...values,
    }

    props.userDataStore.updateCurrentUser(userPayload)
  }

  return (
    <PlaneLoader loading={props.userDataStore.isUserDataLoading}>
      <div className={accountTileCenteredContainerStyles}>
        <Breadcrumb className="account-tile-breadcrumb"label="Back" to={paths.accountPath()} />
        <div className="account-tile-form-wrapper">
          <Form form={form} id="registration-form" onFinish={onFormSubmit} initialValues={initialValues}>
            <Form.Item label="First name" name="firstName" rules={firstNameRules} validateTrigger="onBlur">
              <Input placeholder="Enter" maxLength={50}/>
            </Form.Item>
            <Form.Item label="Last name" name="lastName" rules={lastNameRules} validateTrigger="onBlur">
              <Input placeholder="Enter" maxLength={50}/>
            </Form.Item>
            <Form.Item label="Desired Retirement Age (not age at military retirement/separation)" name="retirementAge" rules={retirementAgeRules}>
              <InputNumber placeholder="Enter" min={55} max={100} />
            </Form.Item>
            <Form.Item label="Birth date" name="birthDate" rules={birthDateRules}>
              <DatePicker />
            </Form.Item>
          </Form>
          <Button loading={props.userDataStore.isSignupInProgress} htmlType="submit" form="registration-form" type="primary" className="register-container-button" >
            Update
          </Button>
        </div>
      </div>
    </PlaneLoader>
  )
}

export default inject('userDataStore')(observer (UserInfoContainer))
