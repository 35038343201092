import { types, flow, getParent, applySnapshot,  getRoot } from 'mobx-state-tree'
import * as paths from 'utils/path-helpers/api'
import axios from 'axios'
import moment from 'moment'
import { message } from 'utils/notifications'
import { TIMELINE_ASSISTANT } from 'constants/wordings/notifications'
import { PROGRESS_STATUSES } from 'constants/modules/'

const humanizeDueIntoDate = date => moment(date).format("DD MMM YY")

const Task = types
  .model('Task', {
    id: types.identifier,
    title: types.optional(types.string, '', [undefined, null, '']),
    description: types.optional(types.string, '', [undefined, null, '']),
    contentType: types.optional(types.string, '', [undefined, null, '']),
    content: types.optional(types.string, '', [undefined, null, '']),
    status: types.optional(types.enumeration(['onHold', 'inProgress', 'completed', 'skipped' ]), 'onHold', [undefined, null, '']),
    dueDate: types.optional(types.string, '', [undefined, null, '']),
  })
  .views(self => ({
    get humanizedDueDate() {
      return humanizeDueIntoDate(self.dueDate)
    },
  }))
  .actions(self => ({
    changeStatus: flow(function*(status) {
      try {
        const payload = {
          status,
          taskId: self.id,
        }

        const response = yield axios.patch(paths.timelineTaskStatusPath(), payload)

        if (getRoot(self).displayType === 'event') {
          yield getParent(self, 2).refetchEvent()
        } else if (getRoot(self).displayType === 'task') {
          applySnapshot(self, response.data)
        }
        message.success(TIMELINE_ASSISTANT.TASK_STATUS_CHANGE({ name: response.data.title, status: PROGRESS_STATUSES[response.data.status].label }))
      } catch (error) {
        console.log(error)
      }
    }),
  }))

const Event = types
  .model('Event', {
    id: types.identifier,
    title: types.optional(types.string, '', [undefined, null, '']),
    description: types.optional(types.string, '', [undefined, null, '']),
    type: types.optional(types.string, '', [undefined, null, '']),
    dueDate: types.optional(types.string, '', [undefined, null, '']),
    completedCount: 0,
    tasks: types.optional(types.array(Task), []),
  })
  .views(self => ({
    get remainingTime() {
      return moment(self.dueDate).fromNow(true)
    },
    get tasksAmount() {
      return self.tasks.length
    },
    get completedTasksPer() {
      return Math.floor((Number(self.completedCount) / Number(self.tasksAmount)) * 100)
    },
    get humanizedDueDate() {
      return humanizeDueIntoDate(self.dueDate)
    },
    get humanizedTimeRemaining() {
      if (self.areAllTasksDone) return 'Done'
      if (self.isOverdue) return 'Overdue'

      return moment(self.dueDate).fromNow(true)
    },
    get areAllTasksDone() {
      return !(Number(self.tasksAmount) - Number(self.completedCount))
    },
    get isOverdue() {
      return !self.areAllTasksDone && moment(self.dueDate).isBefore(moment())
    },
  }))
  .actions(self => ({
    refetchEvent: flow(function*() {
      try {
        const response = yield axios.get(paths.timelineEventPath() + '/' + self.id)
        applySnapshot(self, response.data)
      } catch (error) {
        console.log(error)
      }
    }),
  }))

const EventStore = types
  .model('EventStore', {
    eventTemplates: types.optional(types.array(Event), []),
    areEventTemplatesLoading: true,
    userEvents: types.optional(types.array(Event), []),
    areUserEventsLoading: true,
    userTasks: types.optional(types.array(Task), []),
    areUserTasksLoading: true,
    displayType: types.enumeration(['event', 'task']),
    isEventCreating: false,
  })
  .views(self => ({
    get modulesList() {
      return self.modules.map(module => module.title)
    },
    getEventTemplateById(id) {
      return self.events.find(event => event.id === id)
    },
  }))
  .actions(self => ({
    getUserEvents: flow(function*(userId) {
      self.areUserEventsLoading = true
      try {
        const response = yield axios.get(paths.currentProfileEventsPath(userId))
        self.userEvents = response.data
      } catch (error) {
        self.userEvents = []
        console.log(error)
      }
      self.areUserEventsLoading = false
    }),
    getUserTasks: flow(function*(userId) {
      self.areUserTasksLoading = true
      try {
        const response = yield axios.get(paths.currentProfileTasksPath(userId))
        self.userTasks = response.data
      } catch (error) {
        self.userTasks = []
        console.log(error)
      }
      self.areUserTasksLoading = false
    }),
    getEventTemplates: flow(function*() {
      try {
        self.areEventTemplatesLoading = true
        const response = yield axios.get(paths.timelineTemplatesPath())
        self.eventTemplates = response.data
      } catch (error) {
        console.log(error)
      }
      self.areEventTemplatesLoading = false
    }),
    createEvent: flow(function*(payload) {
      self.isEventCreating = true
      try {
        yield axios.post(paths.timelineEventPath(), payload)
        self.getUserEvents()
        self.getUserTasks()
        message.success(TIMELINE_ASSISTANT.EVENT_CREATION())
      } catch (error) {
        console.log(error)
      }
      self.isEventCreating = false
    }),
    setDisplayType: function(type) {
      self.displayType = type
    },
  }))

export const eventStore = EventStore.create({
  eventTemplates: [],
  userEvents: [],
  userTasks: [],
  displayType: 'event',
})

window.eventStore = eventStore