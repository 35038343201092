// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__serviceMembersContainerStyles___34Ldv{width:100%;height:100%}.styles-module__serviceMembersContainerStyles___34Ldv .service-members-filter-container{display:flex;align-items:center;gap:20px;margin-bottom:20px}.styles-module__serviceMembersContainerStyles___34Ldv .service-members-search-input{height:32px;width:280px}.styles-module__serviceMembersContainerStyles___34Ldv .service-members-table .ant-table-row td:first-child,.styles-module__serviceMembersContainerStyles___34Ldv .service-members-table th:first-child{border-right:none}.styles-module__serviceMembersContainerStyles___34Ldv .assigned-to-column{display:flex;justify-content:center;align-items:center;gap:4px}.styles-module__serviceMembersContainerStyles___34Ldv .assigned-to-column .ant-avatar{min-width:24px;min-height:24px}\n", ""]);
// Exports
exports.locals = {
	"serviceMembersContainerStyles": "styles-module__serviceMembersContainerStyles___34Ldv"
};
module.exports = exports;
