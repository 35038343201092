import React, { useState } from 'react'
import {
  Modal,
  Button,
  Form,
  DatePicker,
  TreeSelect,
} from 'components'
import { observer, inject } from 'mobx-react'
import moment from 'moment'

const dueDateRules = [
  {
    required: true,
    message: 'Please provide due date',
  },
]

const disabledDate = current => {
  return current && current < moment().endOf('day');
}

const TimelineAssistantToolbox = props => {
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = Form.useForm()

  const onOk = () => {
    form.submit()
  }

  const onCancel = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onFormSubmit = async ({ templateId, dueDate }) => {
    const payload = {
      templateId,
      dueDate: dueDate.toISOString(),
    }

    await props.eventStore.createEvent(payload)
    setModalVisible(false)
    form.resetFields()
  }

  return (
    <div className="timeline-assistant-toolbox-wrapper">
      <Button
        disabled={props.userDataStore.selectedMember}
        type="primary"
        id="add-event-button"
        onClick={() => setModalVisible(true)}
      >
        Add event
      </Button>
      <div id="view-switch-wrapper">
        <button
          onClick={() => props.eventStore.setDisplayType('event')}
          className={`timeline-assistant-toolbox-filter-button ${props.eventStore.displayType === 'event' ? 'active' : ''}`}
        >
          By Event
        </button>
        <button
          onClick={() => props.eventStore.setDisplayType('task')}
          className={`timeline-assistant-toolbox-filter-button ${props.eventStore.displayType === 'task' ? 'active' : ''}`}
        >
          By Task
        </button>
      </div>
      <Modal
        title="Add event"
        visible={modalVisible}
        onOk={onOk}
        onCancel={onCancel}
        destroyOnClose
        okText="Add event"
        okButtonProps={{ loading: props.eventStore.isEventCreating }}
      >
        <Form
          id="new-event-form"
          onFinish={onFormSubmit}
          form={form}
        >
          <Form.Item label="Select Event" name="templateId" rules={dueDateRules}>
            <TreeSelect>
              {props.eventStore.eventTemplates.map(event => (
                <TreeSelect.TreeNode
                  key={event.id}
                  value={event.id}
                  title={event.title}
                />
              ))}
            </TreeSelect>
          </Form.Item>
          <Form.Item label="Due date" name="dueDate" rules={dueDateRules}>
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default inject('eventStore')(inject('userDataStore')(observer (TimelineAssistantToolbox)))
