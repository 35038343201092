import React, { useRef, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment'
import { Button, Input, Form, DatePicker, InputNumber, CenteredLayout } from 'components'
import { authContainerStyles } from './styles.module.scss'
import { authPath } from 'utils/path-helpers/app'
import { getLogoPath } from 'utils/instances'

const emailRules = [
  {
    required: true,
    message: 'Please provide your email',
  },
  {
    type: 'email',
    message: 'Please provide valid email',
  },
]

const firstNameRules = [
  {
    required: true,
    message: 'Please provide your first name',
  },
  {
    min: 2,
    message: 'Min first name length == 2',
  },
]

const lastNameRules = [
  {
    required: true,
    message: 'Please provide your last name',
  },
  {
    min: 2,
    message: 'Min first name length == 2',
  },
]

const birthDateRules = [
  {
    required: true,
    message: 'Please provide your birth date',
  },
]

const phoneNumberRules = [
  {
    required: true,
    message: 'Please provide your phone number',
  },
]

const retirementAgeRules = [
  {
    required: true,
    message: 'Please provide your retirement age',
  },
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || !getFieldValue('birthDate') || moment().diff(getFieldValue('birthDate'), 'years') <= value - 5) {
        return Promise.resolve()
      }
      return Promise.reject('Retirement age should be at least 5 years greater than your current age')
    },
  }),
]

const passwordRules = [
  {
    min: 8,
    message: 'Min pass length == 8',
  },
  {
    pattern: new RegExp('[0-9]'),
    message: 'No digits',
  },
  {
    pattern: new RegExp('[A-Z]'),
    message: 'No uppercase',
  },
  {
    pattern: new RegExp('[a-z]'),
    message: 'No lowercase',
  },
  {
    required: true,
    message: 'Please provide your password',
  },
]

const confirmPasswordRules = [
  {
    required: true,
    message: 'Passwords must match',
  },
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject('Passwords do not match')
    },
  }),
]

const initialValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  confirmPassword: '',
  retirementAge: '',
}

const RegisterContainer = props => {
  const phoneInputRef = useRef(null)

  const onFormSubmit = ({ birthDate, ...values }) => {
    const userPayload = {
      birthDate: birthDate.format(),
      ...values,
    }

    props.userDataStore.createUser(userPayload)
  }

  useLayoutEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.classList.add('ant-input', 'custom-input')
    }
  }, [])

  return (
    <CenteredLayout>
      <div className={authContainerStyles} >
        <img
          className='auth-container-logo'
          src={getLogoPath()}
          alt="logo"
        />
        <Form
          id="registration-form"
          onFinish={onFormSubmit}
          initialValues={initialValues}
        >
          <Form.Item label="Email" name="email" rules={emailRules} validateTrigger="onBlur">
            <Input placeholder="Enter" maxLength={50}/>
          </Form.Item>
          <Form.Item label="First name" name="firstName" rules={firstNameRules} validateTrigger="onBlur">
            <Input placeholder="Enter" maxLength={50}/>
          </Form.Item>
          <Form.Item label="Last name" name="lastName" rules={lastNameRules} validateTrigger="onBlur">
            <Input placeholder="Enter" maxLength={50}/>
          </Form.Item>
          <Form.Item label="Birth date" name="birthDate" rules={birthDateRules}>
            <DatePicker />
          </Form.Item>
          <Form.Item label="Phone number" name="phoneNumber" rules={phoneNumberRules}>
            <PhoneInput
              ref={phoneInputRef}
              defaultCountry="US"
              countries={["US"]}
              limitMaxLength={true}
              placeholder="Enter"
            />
          </Form.Item>
          <Form.Item label="Desired Retirement Age (not age at military retirement/separation)" name="retirementAge" rules={retirementAgeRules}>
            <InputNumber placeholder="Enter" min={55} max={100} />
          </Form.Item>
          <Form.Item label="Password" name="password" rules={passwordRules}>
            <Input.Password placeholder="Enter" maxLength={50} />
          </Form.Item>
          <Form.Item label="Confirm password" name="confirmPassword" rules={confirmPasswordRules}>
            <Input.Password placeholder="Enter" maxLength={50} />
          </Form.Item>
        </Form>
        <Button loading={props.userDataStore.isSignupInProgress} htmlType="submit" form="registration-form" type="secondary" >
          Sign Up
        </Button>
        <Button type="primary" htmlType="button" color="secondary" className="register-container-button" >
          <Link to={authPath()}>
            Back to login
          </Link>
        </Button>
      </div>
    </CenteredLayout>
  )
}

export default inject('userDataStore')(observer (RegisterContainer))
