import React, { useMemo } from 'react'
import { Select, Menu } from 'components'
import { asideMenuContainerStyles, asideMenuPageBlockerStyles } from './styles.module.scss'
import * as paths from 'utils/path-helpers/app'
import {
  PartitionOutlined,
  LineChartOutlined,
  CalendarOutlined,
  DollarCircleOutlined,
  LockOutlined,
  FilePdfOutlined,
  SnippetsOutlined,
  IdcardOutlined,
  TeamOutlined,
  TrophyOutlined,
  SolutionOutlined,
  InboxOutlined,
  FolderOpenOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { navigateTo } from 'utils/navigation'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { isFlexAirInstance } from 'constants/instances'
import { USER_ROLES } from 'constants/roles'

const asideMenuItemsData = [
  {
    key: paths.pathwaysPath(),
    icon: <PartitionOutlined />,
    label: 'Pathways',
  },
  {
    key: paths.preferredPathwaysPath(),
    icon: <PartitionOutlined />,
    label: 'Preferred Pathways',
    hidden: !isFlexAirInstance,
  },
  {
    key: paths.careerPath(),
    icon: <LineChartOutlined />,
    label: 'Career Planner',
  },
  {
    key: paths.timelinePath(),
    icon: <CalendarOutlined />,
    label: 'Timeline Assistant',
  },
  {
    key: paths.scholarshipsPath(),
    icon: <TrophyOutlined />,
    label: 'Scholarship Finder',
  },
  {
    key: paths.forecasterPath(),
    icon: <DollarCircleOutlined />,
    label: 'Earnings Forecaster',
    hidden: true,
  },
  {
    key: paths.resourcesPath(),
    icon: <FolderOpenOutlined />,
    label: 'Resources',
  },
  {
    key: paths.gearSavingsPath(),
    icon: <InboxOutlined />,
    label: 'Gear & Savings',
  },
  {
    key: paths.serviceMembersPath(),
    icon: <TeamOutlined />,
    label: 'Service Members',
    roles: [USER_ROLES.COUNSELOR, USER_ROLES.SUPER_COUNSELOR]
  },
  {
    key: paths.flightSchoolFinderPath(),
    icon: <SearchOutlined />,
    label: 'Flight School Finder',
    external: true
  },
  {
    key: paths.consultingPath(),
    icon: <SolutionOutlined />,
    label: 'Consulting',
    hidden: true,
  },
  {
    key: paths.vaultPath(),
    icon: <LockOutlined />,
    label: 'Application Vault',
    hidden: true,
  },
  {
    key: paths.builderPath(),
    icon: <FilePdfOutlined />,
    label: 'Resume Builder',
    hidden: true,
  },
  {
    key: paths.resumePath(),
    icon: <SnippetsOutlined />,
    label: 'Resume Bot',
    hidden: true,
  },
  {
    key: paths.interviewPath(),
    icon: <IdcardOutlined />,
    label: 'Interview Prep',
    hidden: true,
  },
]

const returnAsideMenuItems = role => asideMenuItemsData
  .filter(item => !item.hidden && (!item.roles || item.roles.includes(role)))
  .map(item => (
    <Menu.Item key={item.key}>
      {item.icon}
      {item.label}
    </Menu.Item>
  )
)

const returnAdaptiveBackground = props => {
  const blockerClass = classNames({
    [asideMenuPageBlockerStyles]: true,
    'visible': props.adaptiveStore.isAsideMenuVisible,
  })

  return (
    <div
      className={blockerClass}
      onClick={props.adaptiveStore.hideAsideMenu}
    />
  )
}

const returnServiceMemberSelect = ({ userDataStore, serviceMembers }) => {
  const handleChange = id => userDataStore.updateSelectedServiceMember(id)

  if (userDataStore.role === USER_ROLES.COUNSELOR) {
    return (
      <Select
        showSearch
        allowClear
        placeholder="Select a member"
        value={userDataStore.selectedMember}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
        options={serviceMembers}
        onChange={handleChange}
      />
    )
  }

  return null
}

const ConfirmationContainer = ({ adaptiveStore, serviceMembersStore, userDataStore, match }) => {
  const serviceMembers = useMemo(() => (
    serviceMembersStore.members
      .filter(member => member.counselorId === userDataStore.user.id)
      .map(member => ({ value: member.id, label: `${member.firstName} ${member.lastName}` }))
  ))

  const handleMenuItemSelect = ({ key }) => {
    const selectedItem = asideMenuItemsData.find(item => item.key === key)

    if (selectedItem.external) {
      window.open(key, '_blank')
    } else {
      adaptiveStore.hideAsideMenu()
      navigateTo(key)
    }
  }

  const containerClass = classNames({
    [asideMenuContainerStyles]: true,
    'visible': adaptiveStore.isAsideMenuVisible,
  })

  return (
  <>
    <aside className={containerClass}>
      {returnServiceMemberSelect({ serviceMembers, userDataStore })}
      <Menu onClick={handleMenuItemSelect} selectedKeys={[ match.url ]}>
        {returnAsideMenuItems(userDataStore.role)}
      </Menu>
    </aside>
    {returnAdaptiveBackground({ adaptiveStore })}
  </>
  )
}

export default inject('adaptiveStore')(inject('masterStore')(inject('userDataStore')(inject('serviceMembersStore')(observer (ConfirmationContainer)))))
