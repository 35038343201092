import React from 'react'
import { Button, Input, Form } from 'components'
import { authContainerStyles } from './styles.module.scss'
import { registrationPath } from 'utils/path-helpers/app'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import { navigateToForgotPassword } from 'utils/navigation'
import { getLogoPath } from 'utils/instances'
import { CenteredLayout } from 'components'
import { isFlexAirInstance } from 'constants/instances'

const rules = [
  {
    required: true,
    message: 'This field is required',
  }
]

const initialValues = {
  email: '',
  password: '',
}

const LogInContainer = props => {
  const onFormSubmit = values => {
    const userPayload = {
      password: values.password,
      email: values.email,
    }
    props.userDataStore.loginUser(userPayload)
  }

  return (
    <CenteredLayout>
      <div className={authContainerStyles}>
        <img
          className='auth-container-logo'
          src={getLogoPath()}
          alt="logo"
        />
        <Form
          id="login-form"
          onFinish={onFormSubmit}
          initialValues={initialValues}
        >
          <Form.Item
            label="Email"
            className="auth-container-input"
            name="email"
            rules={rules}
            validateTrigger="onBlur"
          >
            <Input placeholder="Email"/>
          </Form.Item>
          <Form.Item
            label="Password"
            className="auth-container-input"
            name="password"
            rules={rules}
            validateTrigger="onBlur"
          >
            <Input.Password placeholder="Password"/>
          </Form.Item>
        </Form>
        <Button
          type="secondary"
          color="secondary"
          className="sign-up-container-button"
        >
          <Link to={registrationPath()}>
            Sign Up
          </Link>
        </Button>
        <Button
          loading={props.userDataStore.isLoginInProgress}
          htmlType="submit"
          type="primary"
          className={classNames('login-container-button', { 'flex-air-login': isFlexAirInstance })}
          form="login-form"
        >
          Login
        </Button>
        <button
          type="button"
          className="login-container-forgot-password"
          onClick={navigateToForgotPassword}
        >
          Forgot password
        </button>
      </div>
    </CenteredLayout>
  )
}

export default inject('userDataStore')(observer (LogInContainer))
