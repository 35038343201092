import { types, flow } from 'mobx-state-tree'
import axios from 'axios'
import * as API_PATHS from 'utils/path-helpers/api'
import { message } from 'utils/notifications'
import { Path } from './Path'

const findPathByBestTCEParam = (paths, param) => paths.find(path => path[param])

const PathwaysSelectionStore = types
  .model('PathwaysSelectionStore', {
    paths: types.optional(types.array(Path), []),
    arePathsLoading: true,
  })
  .actions(self => ({
    getPaths: flow(function*(userId) {
      self.arePathsLoading = true
      try {
        const response = yield axios.get(API_PATHS.careerPathsPath(userId))
        self.paths = response.data
        self.setPathsTags()
      } catch (error) {
        self.paths = []
        message.error(error.response.data.message)
      }
      self.arePathsLoading = false
    }),
    setPathsTags: function() {
      let path = null
      path = findPathByBestTCEParam(self.paths, 'fastest')
      if (path) path.addTag('Fastest')

      path = findPathByBestTCEParam(self.paths, 'cheapest')
      if (path) path.addTag('Least Expensive')

      path = findPathByBestTCEParam(self.paths, 'profitable')
      if (path) path.addTag('Most Profitable')
    },
  }))

export const pathwaysSelectionStore = PathwaysSelectionStore.create()
