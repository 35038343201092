import React from 'react'
import { Input, Switch } from 'components'

const MembersFilter = ({ searchValue, onSearchChange, viewAssignedOnly, onToggleChange }) => (
  <div className="service-members-filter-container">
    <Input
      allowClear
      className="service-members-search-input"
      placeholder="Search"
      value={searchValue}
      onChange={e => onSearchChange(e.target.value)}
    />
    <Switch
      checked={viewAssignedOnly}
      onChange={onToggleChange}
      checkedChildren="Assigned Only"
      unCheckedChildren="All Members"
    />
  </div>
)

export default MembersFilter