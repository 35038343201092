// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__userProfileContainerStyles___2l4-g .user-profile-sider{border-right:1px solid #d9d9d9}.styles-module__userProfileContainerStyles___2l4-g .user-avatar{display:block;margin:16px auto}.styles-module__userProfileContainerStyles___2l4-g .user-profile-menu{height:100%;border-right:0}.styles-module__userProfileContainerStyles___2l4-g .user-profile-content-layout{background:#fff}.styles-module__userProfileContainerStyles___2l4-g .user-profile-content{background:#fff;padding:24px}.styles-module__userProfileContainerStyles___2l4-g .survey-collapse{margin-top:16px}.styles-module__userProfileContainerStyles___2l4-g .question-answer{margin-bottom:8px}\n", ""]);
// Exports
exports.locals = {
	"userProfileContainerStyles": "styles-module__userProfileContainerStyles___2l4-g"
};
module.exports = exports;
