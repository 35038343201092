import { types, flow } from "mobx-state-tree"
import * as paths from 'utils/path-helpers/api'
import axios from 'axios'
import { PROGRESS_STATUSES } from 'constants/modules'
import { Path } from './Path'
import { masterStore } from 'stores'
import { message } from 'utils/notifications'
import { CAREER_PLANNER, USER_ACCOUNT } from 'constants/wordings/notifications'

const findStepById = (steps, id) => {
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].id === id) return steps[i]
  }

  for (let i = 0; i < steps.length; i++) {
    if (steps[i].steps.length) {
      const step = findStepById(steps[i].steps, id)
      if (step) return step
    }
  }
}

const findModuleById = (modules, id) => {
  return modules.find(module => module.id === id)
}

const CareerStore = types
  .model('CareerStore', {
    activePath: types.union(Path, types.reference(Path), types.undefined),
    masterPaths: types.optional(types.array(Path), []),
    isInitialLoading: true,
    isActivePathDataReloading: false,
  })
  .views(self => ({
    get modules() {
      return (self.activePath && self.activePath.modules) || []
    },
    get modulesList() {
      return self.modules.map(module => module.title)
    },
    moduleById(id) {
      return findModuleById(self.modules, id)
    },
    stepInModuleById(moduleId, stepId) {
      const module = findModuleById(self.modules, moduleId)
      if (module) {
        return findStepById(module.steps, stepId)
      }
      return undefined
    },
    get firstUncompletedModule() {
      return self.modules.find(module => module.status !== PROGRESS_STATUSES.completed.action) || self.modules[0]
    },
    get isActivePathPresented() {
      return self.activePath && self.activePath.id
    },
  }))
  .actions(self => ({
    getDataInitially: flow(function*(userId) {
      try {
        self.isInitialLoading = true
        const response = yield axios.get(paths.careerPathPath(userId))

        if (response.data.id) {
          yield self.getModulesAndStepsProgress(response.data.modules, userId)
          self.activePath = response.data
        }
      } catch (error) {
        self.activePath = undefined
        console.log(error)
      }
      self.isInitialLoading = false
    }),
    getCareer: flow(function*() {
      try {
        const response = yield axios.get(paths.careerPathPath())
        yield self.getModulesAndStepsProgress(response.data.modules)
        self.activePath = response.data
      } catch (error) {
        console.log(error)
      }
    }),
    getModulesAndStepsProgress: flow(function*(modules, userId) {
      try {
        const response = yield axios.get(paths.currentProfilePath(userId))
        self.extractAndSetModulesAndStepsProgress(modules, response.data)
        self.extractAndSetStepsSelection(modules, response.data)
      } catch (error) {
        console.log(error)
      }
    }),
    postStepProgress: flow(function*(payload) {
      self.isActivePathDataReloading = true
      try {
        const response = yield axios.post(paths.careerProgressPath(), payload)
        self.showNotificationsUponStatusChange(self.modules, response.data)
        self.extractAndSetModulesAndStepsProgress(self.modules, response.data)
      } catch (error) {
        console.log(error)
      }
      self.isActivePathDataReloading = false
    }),
    postCareerSelection: flow(function*(payload) {
      self.isActivePathDataReloading = true
      try {
        yield axios.post(paths.careerSelectionPath(), payload)
        yield self.getCareer()
      } catch (error) {
        console.log(error)
      }
      self.isActivePathDataReloading = false
    }),
    extractAndSetModulesAndStepsProgress: function(modules, progressData) {
      progressData.modulesProgress.forEach(moduleProgress => {
        const module = findModuleById(modules, moduleProgress.moduleId)
        if (module) module.status = moduleProgress.status
      })

      progressData.stepsProgress.forEach(stepProgress => {
        const module = findModuleById(modules, stepProgress.moduleId)
        if (module) {
          const step = findStepById(module.steps, stepProgress.stepId)
          if (step) step.status = stepProgress.status
        }
      })
    },
    extractAndSetStepsSelection: function(modules, progressData) {
      progressData.stepsSelection.forEach(selection => {
        const module = findModuleById(modules, selection.moduleId)
        if (module) {
          const step = findStepById(module.steps, selection.value)
          if (step) step.selected = true
        }
      })
    },
    showNotificationsUponStatusChange: function(modules, progressData) {
      progressData.modulesProgress.forEach(moduleProgress => {
        const module = findModuleById(modules, moduleProgress.moduleId)
        if (module) {
          if ((module.status !== moduleProgress.status) && (moduleProgress.status === PROGRESS_STATUSES.completed.action)) {
            message.success(CAREER_PLANNER.MODULE_COMPLETION({ name: module.title }))
          }
        }
      })

      progressData.stepsProgress.forEach(stepProgress => {
        const module = findModuleById(modules, stepProgress.moduleId)
        if (module) {
          const step = findStepById(module.steps, stepProgress.stepId)
          if (step) {
            if (step.status !== stepProgress.status) {
              message.success(CAREER_PLANNER.STEP_STATUS_CHANGE({ name: step.title, status: PROGRESS_STATUSES[stepProgress.status].label }))
            }
          }
        }
      })
    },
    resetProfileData: flow(function*(payload) {
      try {
        yield axios.post(paths.currentProfileResetPath())
      } catch (error) {
        console.log(error)
      }
    }),
    getMasterPaths: flow(function*() {
      try {
        const response = yield axios.get(paths.careerAllPathsPath())
        self.masterPaths = response.data
      } catch (error) {
        console.log(error)
      }
    }),
    updateStepActionAccessDate: flow(function*(id) {
      try {
        yield axios.post(paths.careerContactMePath(id));
        message.success(USER_ACCOUNT.STEP_ACTION_ACCESS_DATE());
      } catch (error) {
        message.error(error.response.data.message)
      }
    }),
    resetProfileProgress: flow(function*() {
      try {
        yield axios.post(paths.currentProfileResetProgressPath())
        masterStore.onAfterRetake()
      } catch (error) {
        console.log(error)
      }
    }),
    resetStoreData: function() {
      self.activePath = undefined;
      self.masterPaths = [];
    },
    setActivePath: function(path) {
      self.activePath = path
    },
  }))

export const careerStore = CareerStore.create()
