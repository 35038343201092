export const CAREER_PLANNER = {
  STEP_STATUS_CHANGE: ({ name, status }) => `Status of ${name} successfully updated to ${status}!`,
  MODULE_COMPLETION: ({ name }) => `${name} successfully completed!`
}

export const TIMELINE_ASSISTANT = {
  TASK_STATUS_CHANGE: ({ name, status }) => `Status of ${name} successfully updated to ${status}!`,
  EVENT_COMPLETION: ({ name }) => `${name} successfully completed!`,
  EVENT_CREATION: () => `Event successfully created!`,
}

export const USER_ACCOUNT = {
  SUBSCRIPTION_UPDATE: ({ subscription }) => `Subscription updated to ${subscription}!`,
  COUPON_VERIFIED: () => `Coupon applied successfully!`,
  COUPON_INVALID: (coupon) => `Promo code "${coupon}" is inactive`,
  GOVX_VERIFIED: () => `Verification successful! Subscription updated!`,
  LOGIN_INVALID_CREDENTIALS: () => 'Credentials are invalid',
  PASSWORD_UPDATE_SUCCESS: () => 'Your password was successfully updated',
  USER_DATA_UPDATE_SUCCESS: () => 'Your user data was successfully updated',
  SERVICE_MEMBER_SELECTION_SUCCESS: () => 'The service member was successfully selected',
  SERVICE_MEMBER_ASSIGNMENT_SUCCESS: () => 'Service member has been successfully assigned',
  SERVICE_MEMBER_UNSELECTION_SUCCESS: () => 'The service member was successfully unselected',
  SERVICE_MEMBER_UNASSIGNMENT_SUCCESS: () => 'Service member\'s assignment has been successfully removed',
  STEP_ACTION_ACCESS_DATE: () => 'You request has already been submitted. We will be in touch shortly. Thank you!',
}
