import { types, flow } from 'mobx-state-tree'
import axios from 'axios'
import * as paths from 'utils/path-helpers/api'
import { message } from 'utils/notifications'
import * as stores from 'stores'
import * as NOTIFICATIONS from 'constants/wordings/notifications'

const Counselor = types.model('Counselor', {
  avatar: types.optional(types.string, ''),
  firstName: types.optional(types.string, ''),
  lastName: types.optional(types.string, ''),
})

const Member = types
  .model('Member', {
    id: types.identifier,
    counselorId: types.maybeNull(types.string),
    counselor: types.maybeNull(Counselor),
    retirementAge: types.optional(types.number, 0),
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    birthDate: types.maybeNull(types.string),
    registrationDte: types.optional(types.string, ''),
    lastLoginDate: types.optional(types.string, ''),
    avatar: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    phoneNumber: types.optional(types.string, ''),
  })

const ServiceMembersStore = types
  .model('ServiceMembersStore', {
    members: types.optional(types.array(Member), []),
    areMembersLoading: true,
    isActionInProgress: false
  })
  .views(self => ({}))
  .actions(self => ({
    getServiceMembers: flow(function* () {
      self.areMembersLoading = true
      try {
        const response = yield axios.get(paths.serviceMembersPath())

        self.members = response.data
      } catch (error) {
        message.error(error.response.data.message)
      }
      self.areMembersLoading = false
    }),
    assignMemberToCounselor: flow(function* (id) {
      self.isActionInProgress = true
      try {
        const response = yield axios.post(paths.serviceMembersPath(), { id })

        const index = self.members.findIndex(member => member.id === id)
        self.members[index] = response.data

        message.success(NOTIFICATIONS.USER_ACCOUNT.SERVICE_MEMBER_ASSIGNMENT_SUCCESS())
      } catch (error) {
        message.error(error.response.data.message)
      }
      self.isActionInProgress = false
    }),
    removeMemberAssignment: flow(function* (id) {
      self.isActionInProgress = true
      try {
        const response = yield axios.put(paths.serviceMembersPath(id))

        const index = self.members.findIndex(member => member.id === id)
        self.members[index] = response.data

        if (id === stores.userDataStore.selectedMember) {
          stores.userDataStore.updateSelectedServiceMember()
        }

        message.success(NOTIFICATIONS.USER_ACCOUNT.SERVICE_MEMBER_UNASSIGNMENT_SUCCESS())
      } catch (error) {
        message.error(error.response.data.message)
      }
      self.isActionInProgress = false
    }),
  }))

export const serviceMembersStore = ServiceMembersStore.create()